import React from "react";
import Promo_list from "../components/Promo/Promo_list";

const Promo = () => {
  return (
    <div>
      <Promo_list />
    </div>
  );
};

export default Promo;
