import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminOrderList from "../components/Orders/AdminOrderList";
const AdminOrders = () => {
  return (
    <div>
      <AdminOrderList />
    </div>
  );
};

export default AdminOrders;
