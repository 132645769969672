import React from "react";
import ManagerOrdersList from "../components/Orders/ManagerOrdersList";

// import AdminOrderList from "../components/Orders/AdminOrderList";

const Orders = () => {
  return (
    <div>
      <ManagerOrdersList />
    </div>
  );
};

export default Orders;
